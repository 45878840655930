<template>
  <div style="padding:20px;" v-if="workspaceID">
    <list :workspaceID="workspaceID" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import List from './List'
export default {
  components: {
    List,
  },
  data: () => ({
    workspaceID: null,
  }),
  computed: {
    ...mapState('app',['locale', 'turismoWorkspace']),
  },
  watch: {
    turismoWorkspaceID () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.workspaceID = this.turismoWorkspace
    },
  },
}
</script>

